import { render, staticRenderFns } from "./consul-slave-alert.vue?vue&type=template&id=e88f1fb2&scoped=true&"
import script from "./consul-slave-alert.vue?vue&type=script&lang=ts&"
export * from "./consul-slave-alert.vue?vue&type=script&lang=ts&"
import style0 from "./consul-slave-alert.vue?vue&type=style&index=0&id=e88f1fb2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e88f1fb2",
  null
  
)

export default component.exports